export const MateriasSourceCuatrimestre =
    [
        {
            semestre: "Primer Semestre",
            materias: [
                {
                    nombre: "ANATOMÍA Y FISIOLOGÍA",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "",
                    clave: "FB-343101",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará las estructuras morfológicas (órganos, sistemas y aparatos del cuerpo humano) y reconocerá los procesos fisiológicos de las estructuras, así como  las funciones vitales del organismo humano por medio de la observación, descripción de modelos anatómicos, analizando información, participando activa durante las técnicas de enseñanza."
                },
                {
                    nombre: "MICROBIOLOGÍA Y PARASITOLOGÍA ",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "",
                    clave: "FB-343102",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante obtendrá el conocimiento de la importancia de la microbiología como una ciencia que estudia a los microorganismos, sus características taxonómicas, morfológicas, fisiológicas, ciclo vital y enfermedades que producen los microorganismos y parásitos más comunes en nuestra región, el medio ambiente en que se desarrollan, para que sean capaz de implementar acciones oportunas preventivas y curativas en el ámbito comunitario  hospitalario en su desarrollo profesional."
                },
                {
                    nombre: "SALUD PÚBLICA Y EPIDEMIOLOGÍA",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasPracticas: "",
                    horasDocente: "48",
                    clave: "FB-343103",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante conocerá la importancia de la salud pública y la epidemiologia y desarrollará habilidades y actitudes para la prevención de enfermedades, así mismo obtendrá las herramientas básicas para reflexionar comprender y explicar  la importancia del método científico y epidemiológico que le permita realizar intervenciones ante cualquier situación de riesgo."
                },
                {
                    nombre: "CRECIMIENTO Y DESARROLLO HUMANO",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "",
                    clave: "FB-343104",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante obtendrá los conocimientos y desarrollará habilidades para identificar las etapas del desarrollo humano con base en sus características físicas, cognitivas y sociales que le permitan proponer cambios y/o acercamientos de estructuras de vida con actitud innovadora, pensamiento crítico, responsabilidad, disciplina, ética y sentido humano."
                },
                {
                    nombre: "COMUNICACIÓN Y EDUCACION PARA LA SALUD ",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasPracticas: "",
                    horasDocente: 32,
                    clave: "FB-343105",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante obtendrá los conocimientos y habilidades para establecer un proceso de comunicación oral y escrita para transmitir  al individuo, familia y comunidad medidos de autocuidado en que promuevan estilos de vidas saludables."
                },
                {
                    nombre: "DIDÁCTICA",
                    ciclo: "1er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343106",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Aplicará la metodología de la enseñanza considerando el texto, pretexto y contexto  que contribuyan a ser más eficaz la función docente de la enfermera(o) en el área de la salud, con pretensión de originar cambios de conducta en los distintos grupos de edad."
                },
            ],
        },
        {
            semestre: "Segundo Semestre",
            materias: [
                {
                    nombre: "FISIOPATOLOGÍA",
                    ciclo: "2do Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343108",
                    creditos: "5",
                    seriacion: "FB-343101",
                    objetivo: "Al finalizar el curso, el estudiante reconocerá las patologías humanas con base en la sintomatología de las mismas que le permitan ubicar su participación en la atención de las diferentes etapas del proceso salud-enfermedad."
                },
                {
                    nombre: "ENFERMERÍA COMUNITARIA",
                    ciclo: "2do Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343110",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante analizará el proceso de enfermería a nivel de la familia y la comunidad y las acciones del sector salud en la contribución del bienestar de la población en el primer nivel de atención."
                },
                {
                    nombre: "BIOQUÍMICA",
                    ciclo: "2do Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343111",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante conocerá los componentes de la materia viva y la relación de la función biológica con la estructura química, el Metabolismo,  los fenómenos biológicos desde el punto de vista molecular y los mecanismos moleculares involucrados en el funcionamiento del organismo humano para analizar y comprender  los cambios que se susciten en él."
                },
                {
                    nombre: "ENFERMERÍA FUNDAMENTAL I",
                    ciclo: "2do Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343111",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante conocerá  la evolución histórica de la enfermería para identificar el papel de la enfermera (o) dentro del equipo de salud y las funciones propias de la disciplina, para aplicar los conocimientos teóricos y procedimientos básicos de enfermería que le permitan proporcionar cuidados integrales, al individuo, familia y/o comunidad."
                },
                {
                    nombre: "PRACTICA DE ENFERMERÍA COMUNITARIA",
                    ciclo: "2do Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "0",
                    clave: "FB-343112",
                    creditos: "20",
                    seriacion: "",
                    objetivo: "Al finalizar el curso, el estudiante desarrollará un diagnóstico de salud dirigido a un área y población específica para plantear alternativas de solución y prevención del binomio salud-enfermedad."
                },
                {
                    nombre: "FILOSOFÍA Y ENFERMERÍA ",
                    ciclo: "2do Semestre",
                    horasIndependientes: "32",
                    horasDocent: "32",
                    horasPracticas: "0",
                    clave: "FB-343113",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "El estudiante Al finalizar el curso estudiante desarrollará competencias cognitivas, actitudinales y conceptuales mediante el ejercicio de la filosofía, para utilizarlas en el desarrollo de su formación profesional."
                },
                {
                    nombre: "INGLÉS I",
                    ciclo: "2do Semestre",
                    horasIndependientes: "16",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343114",
                    creditos: "3",
                    seriacion: "Ninguna",
                    objetivo: "Proporcionar al alumno los conceptos didácticos pertinentes para que desarrolle las competencias lingüísticas del idioma inglés  que le permitan entender conversaciones simples, extraer información relevante de un texto, un artículo o cualquier material audiovisual en torno a temas de asignaturas que estén cursando."
                },
            ],
        },
        {
            semestre: "Tercer Semestre",
            materias: [
                {
                    nombre: "SEXUALIDAD HUMANA",
                    ciclo: "3er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    clave: "FB-343115",
                    horasPracticas: "0",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará los elementos básicos de la sexualidad humana, desde un abordaje psicológico, biológico y social que permita educar en la sexualidad de forma científica y objetiva."
                },
                {
                    nombre: "FARMACOLOGÍA ",
                    ciclo: "3er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343116",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará los conceptos elementales de farmacología y los efectos en el organismo por el uso de fármacos, que permita desarrollar un juicio crítico y analítico integral del riesgo-beneficio en el proceso salud-enfermedad."
                },
                {
                    nombre: "CALIDAD Y SEGURIDAD DEL PACIENTE",
                    ciclo: "3er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343117",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante comprenderá  los conceptos, componentes, características, importancia  y modelos de calidad; así como desarrollará habilidades y actitudes para aplicar la calidad  en  las instituciones de salud para contribuir a la seguridad del paciente."
                },
                {
                    nombre: "ENFERMERÍA FUNDAMENTAL II",
                    ciclo: "3er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343118",
                    creditos: "6",
                    seriacion: "FP-343125",
                    objetivo: "Al finalizar el curso, el estudiante obtendrá  los conocimientos para realizar los procedimientos invasivos que se realizan en la práctica de enfermería y obtendrá las habilidades prácticas para la  realización de las intervenciones  necesarias con la fundamentación científica que le permita proporcionar cuidados integrales, al individuo y la familia."
                },
                {
                    nombre: "PRÁCTICAS DE ENFERMERÍA FUNDAMENTAL II",
                    ciclo: "3er Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "16",
                    clave: "FB-34319",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "El alumno será capaz de adquirir habilidades y destrezas en los procedimientos fundamentales invasivos y no invasivos de enfermería con el uso adecuado de los materiales para el buen desempeño de su ejercicio profesional "
                },
                {
                    nombre: "ÉTICA Y VALORES      ",
                    ciclo: "3er Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343120",
                    creditos: "3",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante interpretará la relevancia de  su dimensión ético-moral y la manera en que contribuye con su formación integral en el contexto personal que le permitan actuar con espíritu crítico y reflexivo, solidez profesional y responsabilidad."
                },
                {
                    nombre: "TECNOLOGÍAS DE LA INFORMACIÓN Y COMPUTACIÓN II",
                    ciclo: "3er Semestre",
                    horasIndependientes: "16",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343121",
                    creditos: "3",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante habrá adquirido conocimientos, habilidades y actitudes para desarrollar competencias lingüísticas del idioma inglés y el interés por comunicarse en el idioma, al mismo tiempo que adquiere estrategias de compresión de lectura aplicadas a  textos con propósitos específicos del área de la salud como parte de su formación profesional."
                },
            ],
        },
        {
            semestre: "Cuarto Semestre",
            materias: [
                {
                    nombre: "GINECOLOGÍA Y OBSTETRÍCIA",
                    ciclo: "4to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    clave: "FB-343122",
                    horasPracticas: "0",
                    creditos: "4",
                    seriacion: "FB-343115",
                    objetivo: "Al finalizar el curso, el estudiante identificará y describirá los cuidados y medidas de prevención y atención en enfermería de los principales trastornos de salud en la mujer en sus diversas etapas aplicar los conocimientos, habilidades, destrezas y actitudes en la atención de Enfermería durante el embarazo, puerperio y recién nacido de bajo riesgo; sus etapas reproductivas,  utilizando como  método científico de Enfermería el  Proceso de Enfermero.  "
                },
                {
                    nombre: "NUTRICIÓN Y DIETOTERAPIA ",
                    ciclo: "4to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343123",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará los conceptos básicos, factores y características de la nutrición y los alimentos, y los aplicará en la planeación de requerimientos nutricionales en las distintas edades del ser humano"
                },
                {
                    nombre: "MODELOS Y TEORÍAS DE ENFERMERÍA ",
                    ciclo: "4to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343124",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante conocerá y analizará los diversos modelos y teorías que fundamentan el ejercicio profesional de la enfermería para que comprenda y aplique los conocimientos con una base científica para otorgar el  cuidado de las personas sanas o enfermas, con la educación y la investigación de Enfermería."
                },
                {
                    nombre: "ENFERMERÍA MATERNO INFANTIL.",
                    ciclo: "4to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343125",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante analizará el proceso de la reproducción humana y describirá la atención y cuidados de enfermería en la madre y el hijo durante y después del parto."
                },
                {
                    nombre: "PRÁCTICA DE ENFERMERÍA MATERNO INFANTIL ",
                    ciclo: "4to Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343126",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará en los escenarios reales los conocimientos, habilidades y actitudes en los pacientes que requieren procedimientos quirúrgicos en las etapas del preoperatorio, transoperatorio y postoperatorio y al paciente en estado crítico utilizando la metodología del proceso enfermero para educar a la persona y familia, prevenir complicaciones, limitar los daños a la salud y lograr la recuperación, con bases científicas humanísticas e incluyentes."
                },
                {
                    nombre: "BIOÉTICA Y LEGISLACIÓN",
                    ciclo: "4to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343127",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al término del curso el estudiante tendrá los conocimientos para: Evaluará situaciones concretas en la formación profesional de enfermería abordando dilemas bioéticos, los cuales resolverá mediante la aplicación del método de estudios de caso desde una perspectiva ético-legal."
                },
                {
                    nombre: "INGLÉS II",
                    ciclo: "4to Semestre",
                    horasIndependientes: "16",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343128",
                    creditos: "3",
                    seriacion: "FP-343135",
                    objetivo: "En este curso el alumno obtendrá las herramientas conceptuales y habilidades que le permitan una mejor fluidez del lenguaje inglés y una mejor  destreza oral receptiva, así como las capacidades de lectura, comunicación oral y comunicación escrita para que tenga una mejor traducción e interpretación de los textos."
                },
            ],
        },
        {
            semestre: "Quinto Semestre",
            materias: [
                {
                    nombre: "ENFERMERÍA EN MEDICINA CRÍTICA",
                    ciclo: "5to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    clave: "FB-343129",
                    horasPracticas: "0",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante contará con los conocimientos, destrezas, habilidades y las competencias profesionales de enfermería para el manejo inmediato del paciente con patologías críticas, que le permitan reducir las complicaciones, discapacidades o la muerte."
                },
                {
                    nombre: "PSICOLOGÍA ",
                    ciclo: "5to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343130",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para fundamentar la práctica de la enfermería en la investigación científica con un enfoque sistemático, reflexivo e interpretativo utilizando los hallazgos de la investigación para mejorar la capacidad de resolución de problemas y la toma de decisiones en el cuidado de la persona, familia y comunidad con bases científicas humanísticas e incluyentes."
                },
                {
                    nombre: "PROCESO ENFERMERO",
                    ciclo: "5to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343131",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante habrá adquirido, conocimientos, habilidades y actitudes para identificar los conceptos y fundamentos teóricos de la psicología y su relación con la enfermería, identificará los procesos mentales, las sensaciones, las percepciones y el comportamiento del ser humano, en relación con el medio ambiente físico y social que lo rodea para entender su actuar en el campo de la salud y colaborar en su salud integral, con bases científicas humanísticas e incluyentes."
                },
                {
                    nombre: "ENFERMERÍA QUIRÚRGICA",
                    ciclo: "5to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343132",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante contará con los conocimientos, destrezas, habilidades y las competencias profesionales del área quirúrgica y los procesos de enfermería utilizados en las etapas del preoperatorio, transoperatorio y postoperatorio de los procedimientos quirúrgicos más frecuentes."
                },
                {
                    nombre: "PRÁCTICAS DE ENFERMERÍA QUIRÚRGICA",
                    ciclo: "5to Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343133",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará los conocimientos, destrezas, habilidades y las competencias quirúrgicas así procesos de enfermería para solucionar problemas médico quirúrgicos  en las etapas del preoperatorio, transoperatorio y postoperatorio."
                },
                {
                    nombre: "DESARROLLO HUMANO",
                    ciclo: "5to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343134",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "El estudiante de la Licenciatura en Enfermería, diseña su proyecto individual para fortalecer su  desarrollo personal y profesional, mediante el  diagnóstico de sus necesidades y potencialidades en las diferentes áreas  de su  vida y  la aplicación de  conocimientos teóricos, habilidades  interpersonales y actitudes propias de los enfoques del desarrollo humano."
                },
            ],
        },
        {
            semestre: "Sexto Semestre",
            materias: [
                {
                    nombre: "PEDIATRÍA",
                    ciclo: "6to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    clave: "FB-343135",
                    horasPracticas: "0",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Reconocerá los factores que afectan el desarrollo del infante normal y tomará importancia del papel que juega la enfermera en la promisión de medidas asistenciales, principalmente en cuanto a prevención se refiere."
                },
                {
                    nombre: "ENFERMERÍA PEDIÁTRICA",
                    ciclo: "6to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FI-343136",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Reconocerá los factores que afectan el desarrollo del infante normal y tomará importancia del papel que juega la enfermera en la promisión de medidas asistenciales, principalmente en cuanto a prevención se refiere."
                },
                {
                    nombre: "SEMINARIO DE TESIS I",
                    ciclo: "6to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FI-343137",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará los elementos, factores de validación, tipos y técnicas de investigación, y producirá informes y escritos académicos propios de su área de formación."
                },
                {
                    nombre: "ENFERMERÍA DEL NIÑO Y DEL ADOLESCENTE",
                    ciclo: "6to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "64",
                    horasPracticas: "0",
                    clave: "FB-343138",
                    creditos: "6",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante valorará las características del crecimiento y desarrollo del niño y el adolescente en sus diferentes etapas e identificará los cuidados específicos de enfermería en estados de salud y de enfermedad"
                },
                {
                    nombre: "PRÁCTICAS CLÍNICAS DE ENFERMERÍA PEDIÁTRICA",
                    ciclo: "6to Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343139",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará el proceso de enfermería en el cuidado del niño y el adolescente en sus diferentes etapas, en estados de salud y de enfermedad."
                },
                {
                    nombre: "DESARROLLO DE HABILIDADES DOCENTES",
                    ciclo: "6to Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343140",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Que el alumno aplique el proceso de enseñanza aprendizaje  en la planeación docente,  la instrumentación y evaluación de un curso teniendo en cuenta la perspectiva social cognoscitiva y constructivista del aprendizaje durante su práctica docente con el profesor asignado."
                },
            ],
        },
        {
            semestre: "Séptimo Semestre",
            materias: [
                {
                    nombre: "GERIATRÍA",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    clave: "FB-343141",
                    horasPracticas: "0",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante analizará el entorno familiar y comunitario del adulto mayor, describirá sus patologías más frecuentes e identificará los procesos de enfermería para su cuidado en situaciones de salud y enfermedad."
                },
                {
                    nombre: "ENFERMEDADES CRÓNICO DEGENERATIVAS",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FI-343142",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará los procedimientos y técnicas de enfermería para el registro, valoración física, valoración nutricional y asistencia del paciente con enfermedades crónico degenerativas."
                },
                {
                    nombre: "BIOESTADÍSTICA",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FI-343143",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará modelos probabilísticos y cálculos de inferencia estadística a la solución de problemas propios dominando las técnicas estadísticas como un proceso del Método Estadístico, que podrá aplicar a una investigación científica en el campo de la salud como una herramienta para la sistematización, análisis de la información y toma de decisiones en beneficio de la población. Beneficiando al campo de salud pública."
                },
                {
                    nombre: "ENFERMERÍA DEL ADULTO MAYOR",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343144",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante reconocerá las patologías más frecuentes en el adulto e identificará las técnicas y procedimiento de enfermería utilizados en su cuidado."
                },
                {
                    nombre: "PRÁCTICA DE ENFERMERÍA DEL ADULTO MAYOR",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343145",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante aplicará los procedimientos y técnicas de enfermería para el registro, valoración física, valoración nutricional y asistencia del adulto mayor en situaciones de salud y enfermedad."
                },
                {
                    nombre: "ENFERMERÍA BASADA EN LA EVIDENCIA",
                    ciclo: "7mo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343146",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso el alumno obtendrá los conocimientos y habilidades para determinar que la investigación es un instrumento útil para transformar la realidad y el contexto de los cuidados de la salud utilizando los hallazgos de la investigación científica  en la práctica de la enfermería para mejorar capacidad de resolución de problemas en entornos nuevos o poco conocidos."
                },
            ],
        },
        {
            semestre: "Octavo Semestre",
            materias: [
                {
                    nombre: "PSIQUIATRÍA",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    clave: "FB-343147",
                    horasPracticas: "0",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante reconocerá los elementos y procesos de enfermería en la valoración y atención del paciente psiquiátrico y la relación existente entre la familia y la comunidad e identificará las principales patologías que le afectan"
                },
                {
                    nombre: "TANATOLOGÍA Y CUIDADOS PALIATIVOS",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FI-343148",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Los conocimientos elementales de los cuidados paliativos y tanatología, para atender y asistir en la esfera biopsicosocial y espiritual a los enfermos terminales y a su familia, durante el proceso de la enfermedad, desarrollando la habilidad de reconocer aquellos factores que influyen en la aceptación de la etapa final de la vida y disminuir  el proceso negativo de la enfermedad."
                },
                {
                    nombre: "SEMINARIO DE TESIS II",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FI-343149",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante valorará las características del crecimiento y desarrollo del niño y el adolescente en sus diferentes etapas e identificará los cuidados específicos de enfermería en estados de salud y de enfermedad"
                },
                {
                    nombre: "ENFERMERÍA EN SALUD MENTAL",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343150",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Evalúa el proceso de salud mental en las diferentes etapas de la vida, identificando las situaciones de riesgo que  impiden el desarrollo de  la salud mental en el individuo, familia y comunidad, adquiriendo los conocimientos necesarios que le permitan intervenir en la prevención y atención de los  trastornos psiquiátricos."
                },
                {
                    nombre: "PRÁCTICAS DE ENFERMERÍA EN SALUD MENTAL",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343151",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante podrá evaluar e identificar oportunamente las alteraciones en salud mental en el  individuo y la dinámica familiar así como situaciones de crisis, realizando acciones para fomentar y promover la salud mental con la aplicación del proceso enfermero para restablecer la salud y los cambios de estilo de vida saludable."
                },
                {
                    nombre: "CULTURA DEL MEDIO AMBIENTE Y SUSTENTABILIDAD",
                    ciclo: "8vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343152",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Identificar y reflexionar en torno a los problemas ambientales con la finalidad de realizar acciones que promuevan el cambio de comportamiento y mentalidad en la relación hombre-naturaleza- cultura- sociedad y así contribuir a la concientización y participación en la protección y cuidado del medio ambiente."
                },
            ],
        },
                {
            semestre: "Noveno Semestre",
            materias: [
                {
                    nombre: "MEDICINA FÍSICA Y REHABILITACIÓN",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    clave: "FB-343153",
                    horasPracticas: "0",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante valorará la discapacidad del paciente y reconocerá las técnicas y procesos de medicina física en enfermería con el fin de rehabilitarlo e integrarlo a su vida productiva."
                },
                {
                    nombre: "PROYECTOS INNOVADORES",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FI-343154",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Diseñar y gestionar un proyecto innovador de servicio a partir de los resultados del estudio del entorno regional, social, profesional y personal, que contribuya a satisfacer las necesidades sociales y de salud, permitiéndole proyectarse en forma independiente de su práctica profesional."
                },
                {
                    nombre: "PREDEFENSA DE TESIS",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FI-343155",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar este curso el alumno integrará y presentará  su proyecto de investigación concluido con todos los apartados que incluye un trabajo de investigación y desarrollará las habilidades para la exposición de la predefensa de su tesis ante el Comité asesor. "
                },
                {
                    nombre: "GERENCIA DE LOS SERVICIOS DE ENFERMERÍA ",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "48",
                    horasPracticas: "0",
                    clave: "FB-343156",
                    creditos: "5",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso, el estudiante identificará los conceptos fundamentales y su relación con el proceso administrativo  y los aplicará en la gerencia de los servicios de enfermería en las instituciones de salud."
                },
                {
                    nombre: "PRÁCTICA DE LOS SERVICIOS DE ENFERMERÍA  ",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "60",
                    horasDocente: "240",
                    horasPracticas: "",
                    clave: "FB-343157",
                    creditos: "20",
                    seriacion: "Ninguna",
                    objetivo: "Al concluir este programa el alumno deberá adquirir las habilidades prácticas para ejecutar las principales actividades del proceso administrativo, la adecuada planeación estratégica y administración de los recursos humanos y materiales en una organización de salud. "
                },
                {
                    nombre: "INTERCULTURALIDAD Y MEDICINA TRADICIONAL",
                    ciclo: "9vo Semestre",
                    horasIndependientes: "32",
                    horasDocente: "32",
                    horasPracticas: "0",
                    clave: "FB-343158",
                    creditos: "4",
                    seriacion: "Ninguna",
                    objetivo: "Al finalizar el curso el alumno obtendrá los conocimientos y habilidades para contextualizar la interculturalidad, analizará los modelos y teorías de la medicina intercultural,  de la medicina convencional y las técnicas utilizadas en la medicina tradicional para su aplicación en la población que atienda."
                },
            ],
        }
    ];